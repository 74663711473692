/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@angular/material/theming";
/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

:root {
  --gray: #e3e3e4;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.modal-backdrop {
  z-index: 1 !important;
  // z-index: 1100 !important;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.consulting {
  .btn-link {
    width: 100%;
  }
}

@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.load-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 990;
  z-index: 1200;
  display: flex;
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  border-radius: 24px;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  &.active {
    width: 300px;
    height: 170px;

    opacity: 1;
    transition: opacity ease-in 0.2s;
  }

  .logo-icon {
    width: 62px;

    height: auto;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loading-icon {
    width: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #07194e;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
